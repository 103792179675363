(function($){
    

    //slider 


$('#slider').slick();

    // var donut = $("#donut").Donutty({ min: 0, max: 100, value: 90 });


    // var text = new Donutty( document.getElementById("donut"), {
    //     bg: "white",
    //     value: 92,
    //     round: false,
    //     color: "#ee462b",
    //     text: function(state) {
    //         return state.value;
    //     }
    // })

    // Contact Form management

    var submitContactForm = function(){

        TweenMax.set('#contact-form-captcha-error', { display: 'none', opacity: 0 });
    
        var captchaReponse  = grecaptcha.getResponse();
    
        if (isSubmittingForm) return;
        isSubmittingForm = true;
    
        var valid = $('#contact-form').valid();
        if (!valid || !captchaReponse) {
            isSubmittingForm = false;
            
            if(!captchaReponse){
                TweenMax.set('#contact-form-captcha-error', { display: 'block', opacity: 1 });
            }
            return;
        }
    
        TweenMax.set('#contact-form-send', { display: 'block', opacity: 0 });
        TweenMax.to('#contact-form-send', 0.25, { opacity: 1 });
    
        $.post(themeBasePath + 'contact.php', $('#contact-form').serialize() + '&token=' + encodeURIComponent(formToken) + '&response=' + captchaReponse)
        .done(function (data, textStatus, jqXHR) {
          TweenMax.set('#contact-form-send', { display: 'none' });
          TweenMax.set('#contact-form-success', { display: 'block', opacity: 1 });
        })
        .fail(function (data, textStatus, jqXHR) {
          TweenMax.set('#contact-form-send', { display: 'none' });
          TweenMax.set('#contact-form-error', { display: 'block', opacity: 1 });
          TweenMax.to('#contact-form-error', 0.25, { display: 'none', opacity: 0, delay: 3 });
          //TweenMax.to('#quote-form-wrapper', 0.25, { opacity: 1, delay: 3 });
          isSubmittingForm = false;
          $.get(themeBasePath + 'token.php', function (token) {
            formToken = token;
          });
        });
    }

    $('#form-submit').click(function(){        
        submitContactForm();
    });


    // SEO and routing management
    var activeNode = 'home';
    var scrollTo = false;

    var processPath = function(location, node){
        if((node.id === activeNode) && node.id !== 'mentions') return;

        activeNode = node.id
        setMeta(location.canonicalPath, node);
        scrollToNode(node);
    }

    var scrollToNode = function(node){
        if(node.id === 'mentions'){
            $('#legacy').modal();
        }else{
            var nodeId = (node.id === 'home')? 'campus': node.id;
            var nodeTarget = $('#' + nodeId);
            
            scrollTo = true;

            $('html, body').animate({
                scrollTop: nodeTarget.offset().top - 80
            }, 2000, 'swing', function() {
                scrollTo = false;
                var $target = $('#' + nodeId);
                $target.focus();
                if ($target.is(":focus")) {
                    return false;
                } else {
                    $target.attr('tabindex','-1');
                    $target.focus();
                };
            });
        }
    }

    var setMeta = function(canonicalPath, node){
        document.title = node.title;
        document.querySelector('meta[name=description]').setAttribute('content', node.description);
        document.querySelector('link[rel=canonical]').setAttribute('href', location.canonicalPath);
    }
    
    var dataNode = [
        {id: "home", path: '/', title: 'Campus Mecateam', description: 'Mecateamcluster est un pôle unique autour de la conception, réalisation et maintenance d’engins de travaux ferroviaires.'},
        {id: "campus", path: '/campus', title: 'Campus Mecateam : présentation du campus', description: 'Campus Mecateam est issu d’une réflexion collaborative entre des entreprises de travaux ferroviaires et des constructeurs...'},
        {id: "formations-alternances", path: '/formations-alternances', title: 'Campus Mecateam : catalogue des formations en alternances', description: 'Identifier vos besoisn et découvrez les différentes formations en alternances pour la maintenance des engins de travaux ferroviaire.'},
        {id: "formations-continues", path: '/formations-continues', title: 'Campus Mecateam : catalogue des formations continues', description: 'Identifier vos besoisn et découvrez les différentes formations continues pour la maintenance des engins de travaux ferroviaire.'},
        {id: "infosPratiques", path: '/infosPratiques', title: 'Campus Mecateam : informations pratiques', description: 'Nos formations peuvent se dérouler sur notre plateforme aménagée en Bourgogne ou au sein des entreprises, ainsi que dans des centres de formation.'},
        {id: "contact", path: '/contact', title: 'Campus Mecateam : nous contacter', description: 'Contactez-nous pour en savoir sur nos parcours et formations en maintenance d\'engins de travaux ferroviaires'},
        {id: "mentions", path: '/mentions', title: 'Campus Mecateam : mentions légales', description: ''},
    ]
  
    var basePath = $('head base').attr('href');
    var trimmedBasePath = (basePath.indexOf(location.origin) === 0) ? basePath.substring(location.origin.length) : basePath;

    page.base(trimmedBasePath);

    _.forEach(dataNode, function(node){
        page(node.path, function(location) { processPath(location, node); });
        page(_.trim(node.path, '/'), function(location) { processPath(location, node); });
    });

    page('*', function(route) {
        page(basePath, options);
    });

    var options = { hashbang: true };
    page(options);

    // Form
    var formToken;
    var isSubmittingForm;

    $.validator.messages.required = '&nbsp;&nbsp;Obligatoire';
    $('#contact-form').validate();

    // console.log('base: '+basePath);
    $.get(themeBasePath + "token.php").done(function(data){formToken = data;});
    



    // Sticky menu 
    $(window).scroll(function(){
        
        $(".navbar li > a.active").each(function(){

            if(!scrollTo){
                var currentItemTitle = $(this).attr('title');
                var currentItem = $(this).text().toLowerCase();  

                var currentNode = _.trim($(this).attr('data-target'), '#');
                var currentNodedata = _.find(dataNode, {id: currentNode});

                window.history.replaceState(currentNode, "", '#!/'+ currentNode);
                setMeta(trimmedBasePath + '#!/' + currentNode, currentNodedata);
            }
        })

        if ($(window).scrollTop() >= 110) {
            $('body').addClass('scrolled');
            $('.top').addClass('fixed-header');
        }
        else {
            $('.top').removeClass('fixed-header');
        }
    });
    
    $('.logo-light').on('click', function(){
        $('.navbar-collapse').removeClass('show');
        $('#btnMenu').attr('aria-expanded', 'false')
        $('body').removeClass('noScroll')
    })
    
    // toggleClass (show) on click list menu 
    $('.nav-link').on('click', function(){
        $('.navbar-collapse').toggleClass('show');
        // remove scroll on body if navmenu active
        $('body').toggleClass('noScroll')
        $('#btnMenu').attr('aria-expanded', 'false')
    })
    
    // remove scroll on body if navmenu active
    $('#btnMenu').on('click', function(){
        $('body').toggleClass('noScroll')
    })

    var isMobile = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function() {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function() {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function() {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function() {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };
    if( isMobile.any() ) {
        $('.section.s5 .a2').removeAttr('id')
        $('.section.s5 .a2 .contact').attr('id', 'contact')
    } else {        
        $('.section.s5 .a2 .contact').removeAttr('id')
        $('.section.s5 .a2').attr('id', 'contact')
    }     
    
    // -----------------------
    var ctrl = new ScrollMagic.Controller();
    
    var headerContent   = $('#campus');
    var sectionS1_a1    = $('.section.s1 .a1');
    var sectionS1_a2    = $('.section.s1 .a2');
    var sectionS1_a3    = $('.section.s1 .a3');
    var sectionS22      = $('.section.s22');
    var sectionS2       = $('.section.s2');
    var sectionS3       = $('.section.s3');
    var sectionS4_a1    = $('.section.s4 .a1');
    var sectionS4_a2    = $('.section.s4 .a2');
    var sectionS5_a1    = $('.section.s5 .a1');
    var sectionS5_a2    = $('.section.s5 .a2');
    var footer          = $('.footer');

    
    
    // Create scenes for header (start 1)
    $(headerContent).each(function(i) {

        var $img_home1 = $('#campus .banner .pic img')
        var $img_home2 = $('#campus .picBg img')
        // -----------------------------------
        TweenMax.set($img_home1, {scale:0.8})
        TweenMax.set($img_home2, {scale:0.8})

        var tl_1 = new TimelineMax()

        // animations 
        .to(headerContent.find('.banner .pic'), 3, {autoAlpha: 1, display:"block"}, '#start')
        .to(headerContent.find('.resum .title'), 1, {autoAlpha: 1, top: 0}, '0.5')
        .to(headerContent.find('.separate'), 0.5, {autoAlpha: 1, top: 0}, '0.9')
        .to(headerContent.find('.resum .para'), 0.5, {autoAlpha: 1, top: 0}, '1.4')
        .to(headerContent.find('.picBg img'), 0.8, {autoAlpha: 1, scale: 1}, '1.4')
        .to(headerContent.find('.banner .pic img'), 3, {autoAlpha: 1, display:"block", scale: 1}, '#start')
        .to(headerContent.find('.top .logo-full'), 0.8, {autoAlpha: 1}, '0.7')
        .to(headerContent.find('.top .navbar'), 1, {autoAlpha: 1}, '1.5')

        // -----------------------------------
    
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.85,
            reverse: false
        })
        .setTween(tl_1)
        .addTo(ctrl);
    });

    // Create scenes for section 1 a1 (start 2)
    $(sectionS1_a1).each(function(i) {
        // -----------------------------------
        var tl_1_a1 = new TimelineMax()

        // animations 
        tl_1_a1.from(sectionS1_a1.find('.pic img'), 0.45, {autoAlpha: 0, scale: 0.8})
        tl_1_a1.from(sectionS1_a1.find('.resum .title'), 0.45, {autoAlpha: 0, y: 50})
        tl_1_a1.from(sectionS1_a1.find('.resum .para'), 0.45, {autoAlpha: 0, bottom: -50})
        tl_1_a1.from(sectionS1_a1.find('.contentBtn'), 0.45, {autoAlpha: 0, y: 50})
        
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.85,
            reverse: false
        })
        .setTween(tl_1_a1)
        .addTo(ctrl);
    });

    // Create scenes for section 1 a2 (start 3)
    $(sectionS1_a2).each(function(i) {
        // -----------------------------------
        var tl_1_a2 = new TimelineMax()

        // animations 
        .from(sectionS1_a2.find('.title'), 0.45, {autoAlpha: 0, y: 50})
        .from(sectionS1_a2.find('.pic img'), 2, {autoAlpha: 0, scale: 0.8})
        .from(sectionS1_a2.find('.resum .logo'), 0.45, {autoAlpha: 0, y: 70, scale: 0.8}, '0.5')
        .from(sectionS1_a2.find('.resum .para'), 0.45, {autoAlpha: 0, bottom: -50}, '0.9')
    
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.85,
            reverse: false
        })
        .setTween(tl_1_a2)
        .addTo(ctrl);
    });

    // Create scenes for section 1 a3 (start 4)
    $(sectionS1_a3).each(function(i) {
        // -----------------------------------
        var tl_1_a3 = new TimelineMax()

        // animations 
        .from(sectionS1_a3.find('.resum .logo'), 0.45, {autoAlpha: 0, y:70, scale: 0.8})
        .from(sectionS1_a3.find('.resum .para'), 0.45, {autoAlpha: 0, bottom: -70})
        .from(sectionS1_a3.find('.pic img'), 2, {autoAlpha: 0, scale: 0.8})
        .from(sectionS1_a3.find('.title'), 1, {autoAlpha: 0, y: 70}, '0.5')
    
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.85,
            reverse: false
        })
        .setTween(tl_1_a3)
        .addTo(ctrl);
    });

    // Create scenes for section 22 (start 5)
    $(sectionS22).each(function(i) {
        // -----------------------------------
        var tl_22 = new TimelineMax()

        // animations 
        .from(sectionS2.find('.a1 .bgPic img'), 0.45, {autoAlpha: 0})
        .from(sectionS22.find('.a1 .title, .a1 .para'), 0.45, {autoAlpha: 0, y: 70})
        // .from(sectionS22.find('.a1 .map .vLine'), 0.45, {autoAlpha: 0, height: 0})
        .staggerFrom(sectionS22.find('.a1 .listFormation'), 0.3, {autoAlpha: 0, y: 50}, 0.3)
    
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.85,
            reverse: false
        })
        .setTween(tl_22)
        .addTo(ctrl);
    });

    // Create scenes for section 2 (start 5)
    $(sectionS2).each(function(i) {
        // -----------------------------------
        var tl_2 = new TimelineMax()

        // animations 
        // .from(sectionS2.find('.a1 .bgPic img'), 0.45, {autoAlpha: 0})
        .from(sectionS2.find('.a1 .title'), 0.45, {autoAlpha: 0, y: 70})
        .from(sectionS2.find('.a1 .map .vLine'), 0.45, {autoAlpha: 0, height: 0})
        .staggerFrom(sectionS2.find('.a1 .listBtn a'), 0.3, {autoAlpha: 0, y: 50}, 0.3)
    
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.85,
            reverse: false
        })
        .setTween(tl_2)
        .addTo(ctrl);
    });

    // Create scenes for section 3 (start 6)
    $(sectionS3).each(function(i) {
        // -----------------------------------
        var tl_6 = new TimelineMax()

        // animations 
        .from(sectionS3.find('.a1 .resum .title-wrapper'), 0.8, {autoAlpha: 0, top: 70})
        .from(sectionS3.find('.a1 .bgPic img'), 1, {autoAlpha: 0, scale: 0.8})
        .from(sectionS3.find('.a1 .formation'), 0.45, {autoAlpha: 0, y:70, scale: 0.8})
        .from(sectionS3.find('.a1 .schedule'), 0.45, {autoAlpha: 0, y:70, scale: 0.8})
    
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.85,
            reverse: false
        })
        .setTween(tl_6)
        .addTo(ctrl);
    });

    // Create scenes for section 4 a1 (start 7)
    $(sectionS4_a1).each(function(i) {
        var tl_7 = new TimelineMax()

        // animations 
        .from(sectionS4_a1.find('.logo img'), 0.45, {autoAlpha: 0, y: 70})
        .from(sectionS4_a1.find('.title'), 0.45, {autoAlpha: 0, y: 70})
        .from(sectionS4_a1.find('.pic img'), 2, {autoAlpha: 0, scale: 0.8})
        .from(sectionS4_a1.find('.contentBtn'), 0.45, {autoAlpha: 0, y: 70}, '0.8')
    
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.85,
            reverse: false
        })
        .setTween(tl_7)
        .addTo(ctrl);
    });

    // Create scenes for section 4 a2 (start 8)
    $(sectionS4_a2).each(function(i) {
        var tl_8 = new TimelineMax()

        // animations 
        //.from(sectionS4_a2.find('.pic img'), 1, {autoAlpha: 0, scale: 0.8})
        .from(sectionS4_a2.find('.para'), 0.3, {autoAlpha: 0, y: 50}, 0.3)
    
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.85,
            reverse: false
        })
        .setTween(tl_8)
        .addTo(ctrl);
    });

    // Create scenes for section 5 a1 (start 9)
    $(sectionS5_a1).each(function(i) {
        var tl_9 = new TimelineMax()

        // animations 
        .from(sectionS5_a1.find('.pic img'), 0.45, {autoAlpha: 0, scale: 0.8})
        .from(sectionS5_a1.find('.title'), 0.45, {autoAlpha: 0, y: 70})
        .from(sectionS5_a1.find('.para, .qualiopi'), 0.45, {autoAlpha: 0, y: 70})
    
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.85,
            reverse: false
        })
        .setTween(tl_9)
        .addTo(ctrl);
    });

    // Create scenes for section 5 a2 (start 10)
    $(sectionS5_a2).each(function(i) {
        var tl_10 = new TimelineMax()

        // animations 
        .from(sectionS5_a2.find('.logo'), 0.45, {autoAlpha: 0, y: 70}, '#sTtr')
        .from(sectionS5_a2.find('.title'), 0.45, {autoAlpha: 0, y: 70}, '#sTtr')
        .from(sectionS5_a2.find('.subTitle'), 0.45, {autoAlpha: 0, y: 70}, '#start')
        .from(sectionS5_a2.find('.contentForm'), 0.45, {autoAlpha: 0, y: 70}, '#start')
        .from(sectionS5_a2.find('.g-recaptcha'), 0.45, {autoAlpha: 0, y: 70}, '#start')
        .from(sectionS5_a2.find('.contentBtn'), 0.45, {autoAlpha: 0, y: 70}, '#start')
        .from(sectionS5_a2.find('.para'), 0.45, {autoAlpha: 0, y: 70}, '#start')
    
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.85,
            reverse: false
        })
        .setTween(tl_10)
        .addTo(ctrl);
    });

    // Create scenes for footer (start 11)
    $(footer).each(function(i) {
        var tl_11 = new TimelineMax()

        // animations 
        .from(footer.find('.top .para'), 1, {autoAlpha: 0, scale: 0.3})
        .staggerFrom(footer.find('.bottom .logoPart'), 0.3, {autoAlpha: 0, scale: 0.8, y: 70}, 0.3)
        .from(footer.find('.bottom .para'), 0.5, {autoAlpha: 0, y: 70}, 0.3)
        .from(footer.find('.legacy'), 0.5, {autoAlpha: 0, y: 70}, 0.3)
    
        new ScrollMagic.Scene({
            triggerElement: this,
            triggerHook: 0.85,
            reverse: false
        })
        .setTween(tl_11)
        .addTo(ctrl);
    });





})(jQuery);


            